<script lang="ts">
  import "@fontsource-variable/inter";
  import inter from "@fontsource-variable/inter/files/inter-latin-wght-normal.woff2?url";
  import stylesheet from "~/app.css?url";
  import { QueryClient, QueryClientProvider } from "@tanstack/svelte-query";
  import favicon from "~/lib/assets/images/favicon.webp";
  import Navbar from "~/lib/components/Navbar.svelte";
  import { browser } from "$app/environment";

  let { children } = $props();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        enabled: browser,
      },
    },
  });
</script>

<svelte:head>
  <link rel="shortcut icon" href={favicon} type="image/x-icon" />
  <link
    rel="preload"
    href={inter}
    as="font"
    type="font/woff2"
    crossorigin="anonymous"
  />
  <link rel="preload" href={stylesheet} as="style" crossorigin="anonymous" />
  <link rel="stylesheet" href={stylesheet} crossorigin="anonymous" />
</svelte:head>

<Navbar />

<QueryClientProvider client={queryClient}>
  <div class="flex grow flex-col px-4">
    {@render children?.()}
  </div>
</QueryClientProvider>

<footer class="border-t border-t-neutral-500 p-4 text-center">
  &copy; {new Date().getFullYear()} Winterbolt Games. All rights reserved.
</footer>
